
import { defineComponent, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useBlocks, useDeleteBlock } from '@/composables/api';
import { BlockIdOptions } from '@/services/api';

import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Type',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Video',
        value: 'video'
      },
      {
        label: 'Model',
        value: 'model'
      },
      {
        label: 'Advertisement',
        value: 'advertisement'
      },
      {
        label: 'Photo',
        value: 'photo'
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref('');
    const type = ref('');
    const { data, isLoading, isFetching, refetch } = useBlocks({ page, keyword, type });
    const { isLoading: isDeletedLoading, mutate } = useDeleteBlock();

    const statusIndex = ref(undefined);

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      type.value = event[0];
    };

    const deleteBlock = ({ blockId }: BlockIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { blockId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    return {
      data,
      page,
      isLoading,
      isFetching,
      isDeletedLoading,
      deleteBlock,
      statusIndex,
      searchKeyword,
      FILTER_OPTIONS,
      handleFilterChange
    };
  }
});
